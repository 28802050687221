<template>
	<div class="supply_demand">
		<div class="left_center">
			<div class="tit">供求中心</div>
			<div class="condition">
				<div class="mode" :class="current_mode == 2 ? 'active':'' " @click="changeType(2)">供应</div>
				<div class="mode" :class="current_mode == 1 ? 'active' : '' " @click="changeType(1)">求购</div>
				<div class="search">
					<el-date-picker class="date_select first" value-format="yyyy-MM-dd" v-model="start_time" type="date" placeholder="开始时间"></el-date-picker>-
					<el-date-picker class="date_select second" value-format="yyyy-MM-dd" v-model="end_time" type="date" placeholder="截止时间"></el-date-picker>
					<div class="commit" @click="searchList()">确定</div>
				</div>
			</div>
			<div class="list_tit">
				<div class="name">货物信息</div>
				<div class="demand">求购量</div>
				<div class="time">截止时间</div>
				<div class="company">企业信息</div>
				<div class="phone">手机号码</div>
			</div>
			<div class="supply_demand_list" v-loading="loading">
				<div class="demand_item" v-for="(item,index) in supply_demand_list" :key="index" @click="toSupplyDemandDetail(item.id)">
					<div class="demand_tit">
						<div class="name">{{item.goods_name}}</div>
						<div class="time">发布日期：{{$util.timeStampTurnTime(item.create_time).split(' ')[0]}}</div>
					</div>
					<div class="number"><span>{{item.num}}</span>{{item.unit || '件'}}</div>
					<div class="end_time">
						<div class="time">{{$util.timeStampTurnTime(item.deadline_time).split(' ')[0]}}</div>
						<!-- <div class="remaining_time" v-if="remainingTime(item.deadline_time,'css')">剩余 <span>{{remainingTime(item.deadline_time,'')}}</span> 天</div> -->
					</div>
					<div class="company_name">{{item.corporate_name}}</div>
					<div class="phone">{{item.phone}}</div>
				</div>
			</div>
			<div class="pager">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
			</div>
		</div>
		<div class="right_info">
			<div class="publish_info">
				<div class="tit">发布供求信息</div>
				<div class="num">已有<span>{{SupplyDemandCount}}</span>发布供求信息</div>
				<div class="form_info">
					<div class="choose-btn">
						<div class="choose-type">
							类型：
						</div>
						<div class="btn" v-for="(item,index) in gqbtn" :key="index" @click="gqIndex = index" :class="index == gqIndex ? 'gong-styles' : ''">
							{{item.name}}
						</div>
					</div>
					<input type="text" placeholder="物品名称" class="goods_name" v-model="purchase_name">
					<div class="spec">
						<input type="text" placeholder="采购量" class="purchase_num" v-model="purchase_num">
						<input type="text" placeholder="单位" class="unit" v-model="purchase_unit">
					</div>
					<el-date-picker class="choose_time" value-format="yyyy-MM-dd" v-model="purchase_time" type="date" placeholder="截止时间 例如:2023-08-10"></el-date-picker>
					<div class="more">
						<span class="more-add" @click="dialogVisible = true">+ 图文发布</span>
					</div>
					<input type="text" placeholder="联系电话" class="mobile" v-model="purchase_contact_phone">
					<div class="verification_code">
						<input type="text" placeholder="手机验证码" class="code" v-model="code">
						<div class="btn" @click="getCode()">{{ dynacodeData.codeText }}</div>
					</div>
				</div>
				<div class="publish">
					<div class="btn" @click="publishPurchase()">立即发布</div>
				</div>
			</div>
			<div class="recent_transaction">
				<div class="tit">最新成交</div>
				<div class="record_list">
					<template v-if="recentTransactions.length">
						<div class="record_item" v-for="(item,index) in recentTransactions" :key="index" v-show="index<7">
							<div class="left_info">
								<div class="name">{{item.sku_name}}</div>
								<div class="time">{{$util.timeStampTurnTime(item.create_time)}}</div>
							</div>
							<div class="price">￥{{item.goods_money}}</div>
						</div>
					</template>
					<div v-else class="empty_data">暂无数据</div>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="dialogVisible" title="图文上传">
			<div class="more-item">
				<div class="dialog-title">
					添加图片：
				</div>
				<el-upload ref="upload" :action="uploadActionUrl" class="upload-box"
				:limit="9"
				 list-type="picture-card" :on-success="
					(file, fileList) => {
						return handleSuccess(file, fileList);
					}
				" :on-preview="handlePictureCardPreview" :on-remove="
					(file, fileList) => {
						return handleRemove(file, fileList);
					}
				"
				:on-exceed="onExceed"
				>
				<i class="el-icon-plus"></i>
				</el-upload>
			</div>
			<div class="more-item">
				<div class="dialog-title">
					简介：
				</div>
				<div class="content">
					<el-input
						type="textarea"
						placeholder="请输入简介信息"
						v-model="contentText"
						class="buyer-message"
						maxlength="140"
						show-word-limit
						resize="none"
					></el-input>
				</div>
			</div>
			<div class="publish-close">
				<div class="btn" @click="dialogVisible = false">确定</div>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="dialogImgVisible"><img width="100%" :src="dialogImageUrl" alt="" /></el-dialog>
	</div>
</template>

<script>
	import { supplyDemandPageList, addSupplydemand } from '@/api/organization';
	import { RecentTransactions, mobileCode } from "@/api/index";
	import { mapGetters } from "vuex"
	import Config from '@/utils/config';
	export default {
		data() {
			return {
				gqbtn:[
					{
						name:'求购'
					},
					{
						name:'供应'
					},
				],
				dialogImageUrl: '',
				current_mode:2,
				supply_demand_list:[],
				loading:true,
				total: 0,
				currentPage: 1,
				pageSize: 6,
				start_time:'',
				end_time:'',
				recentTransactions:[],//最新成交数据
				SupplyDemandCount:0,
				purchase_name:'',
				purchase_num:'',
				purchase_unit:'',
				dialogVisible: false,
				purchase_time:'',
				purchase_contact_phone:'',
				code:'',//手机验证码
				key:'',
				dynacodeData: {
					seconds: 120,
					timer: null,
					codeText: "获取动态码",
					isSend: false
				}, // 动态码
				dialogImgVisible:false,
				uploadActionUrl: Config.baseUrl + '/api/upload/headimg',
				upload_img:'',
				imageList:[],
				contentText:'',
				gqIndex:0
			}
		},
		created() {
			this.getSupplyDemandList();
			this.getRecentTransactions();
		},
		watch: {
			"dynacodeData.seconds": {
				handler(newValue, oldValue) {
					if (newValue == 0) {
						clearInterval(this.dynacodeData.timer)
						this.dynacodeData = {
							seconds: 120,
							timer: null,
							codeText: "获取动态码",
							isSend: false
						}
					}
				},
				immediate: true,
				deep: true
			}
		},
		computed:{
			...mapGetters(["token"])
		},
		methods: {
			onExceed(){
				this.$message.error('最多只能上传9张')
			},
			handlePictureCardPreview(file) {
			  // 点开大图
			  this.dialogImageUrl = file.url;
			  this.dialogImgVisible = true;
			},
			handleRemove(file, fileList) {
				this.imageList.forEach((item,index)=>{
					if(item.name == file.name){
						this.imageList.splice(index,1)
					}
				})
			},
			handleSuccess(file, fileList) {
				let obj = {
					name:file.data.pic_name,
					url:file.data.pic_path
				}
				this.imageList.push(obj)
				console.log(file)
			},
			toSupplyDemandDetail(id){
				this.$router.pushToTab('/supplyDemand/detail-'+id)
			},
			getCode(){
				if (this.dynacodeData.seconds != 120) return
				if(!this.purchase_contact_phone){
					this.$message.error('请输入手机号')
					return;
				}
				mobileCode({
					phone:this.purchase_contact_phone
				})
				.then(res=>{
					if(res.code>=0){
						this.key = res.data.key;
						if (this.dynacodeData.seconds == 120 && this.dynacodeData.timer == null) {
							this.dynacodeData.timer = setInterval(() => {
								this.dynacodeData.seconds--
								this.dynacodeData.codeText = this.dynacodeData.seconds + "s后可重新获取"
							}, 1000)
						}
					}
				})
			},
			publishPurchase(){
				let arr = []
				this.imageList.forEach((item,index)=>{
					arr.push(item.url)
				})
				if (!this.token) {
					this.$message({
						message: '请先去登录',
						type: 'warning'
					});
					this.$router.push({ path: '/login'});
					return;
				}
				if(this.$util.memberStatus() != 2){
					this.$message({
						message: '您还未认证请先进行认证',
						type: 'warning'
					});
					this.$router.push({ name: 'authentication'});
					return;
				}
				var phonereg = /^1[3-9]\d{9}$/;
				if(!this.purchase_name){
					this.$message.error('物品名称不能为空')
					return;
				}
				if(!this.purchase_num){
					this.$message.error('采购量不能为空')
					return;
				}
				if(!this.purchase_unit){
					this.$message.error('采购单位不能为空')
					return;
				}
				if(!this.purchase_time){
					this.$message.error('采购的截止时间不能为空')
					return;
				}
				if( this.$util.timeTurnTimeStamp(this.purchase_time) < ( new Date().getTime() / 1000 ) ){
					this.$message.error('采购的截止时间不得早于当天')
					return;
				}
				if(!this.purchase_contact_phone){
					this.$message.error('联系电话不能为空')
					return;
				}
				if(!phonereg.test(this.purchase_contact_phone)){
					this.$message.error('联系电话格式不正确')
					return;
				}
				if(!this.code){
					this.$message.error('请输入手机验证码')
					return;
				}
				addSupplydemand({
					goods_name:this.purchase_name,
					num:this.purchase_num,
					deadline_time:this.purchase_time,
					phone:this.purchase_contact_phone,
					unit:this.purchase_unit,
					code:this.code,
					key:this.key,
					describe:this.contentText,
					atlas:arr.toString(),
					type:this.gqIndex == 0 ? 1 : 2
				})
				.then(res=>{
					if(res.code>=0){
						this.$message(res.message);
						this.purchase_name = '';
						this.purchase_num = '';
						this.purchase_unit = '';
						this.purchase_time = '';
						this.purchase_contact_phone = '';
						this.code = '';
						this.key = '';
						this.$router.push('/member/my_supply')
					}else{
						this.$message.error(res.message)
					}
				})
			},
			getRecentTransactions(){
				RecentTransactions()
				.then(res=>{
					if(res.code>=0){
						this.recentTransactions = res.data.list;
					}
				})
			},
			searchList(){
				if(this.start_time && this.end_time){
					if( this.$util.timeTurnTimeStamp(this.start_time) > this.$util.timeTurnTimeStamp(this.end_time) ){
						this.$message({message:'截止时间不能早于开始时间'});
						return;
					}
				}
				this.loading = true;
				this.getSupplyDemandList();
			},
			remainingTime(time,mode){
				var current_time = parseInt(new Date().getTime()/1000);
				if(time > current_time ){
					if(mode=='css'){
						return true;
					}
					return this.$util.countDown(time-current_time).d
				}else{
					if(mode=='css'){
						return false;
					}
				}
			},
			getSupplyDemandList() {
				supplyDemandPageList({
					page:this.currentPage,
					page_size:this.pageSize,
					type:0,
					is_my:0,
					start_time:this.start_time,
					end_time:this.end_time
				})
				.then(res=>{
					if(res.code>=0){
						this.supply_demand_list = res.data.list;
						this.total = res.data.count;
						this.loading = false;
					}
					this.loading = false;
				})
				supplyDemandPageList({
					page:this.currentPage,
					page_size:this.pageSize,
					type:1,
				})
				.then(res=>{
					if(res.code>=0){
						this.SupplyDemandCount = res.data.count;
					}
				})
			},
			changeType(mode){
				this.current_mode = mode;
				this.currentPage = 1;
				this.loading = true;
				this.getSupplyDemandList();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page
				this.getSupplyDemandList();
			},
			handlePageSizeChange(size) {
				this.pageSize = size
				this.getSupplyDemandList();
			},
		},
	}
</script>

<style lang="scss">
	.date_select{
		width: 120px !important;
		::-webkit-input-placeholder{
			color: #C0C4CC;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
		}
		.el-input__prefix{
			display: none;
		}
		.el-input__inner{
			border-radius: 0;
			border:1px solid #DCDFE6;
			border-radius: 0;
			height: auto !important;
			padding: 5px 16px;
			color: #222;
			font-size: 14px;
			line-height: 20px;
		}
		.el-input__icon{
			line-height: 32px;
		}
		
	}
	.choose_time{
		width: auto !important;
		margin-bottom: 10px;
		::-webkit-input-placeholder{
			color: #888;
		}
		.el-input__prefix{
			display: none;
		}
		.el-input__inner{
			border-radius: 0;
			border:1px solid #E5E5E5;
			height: auto !important;
			padding: 8px;
			color: #333;
			font-size: 12px;
			line-height: 16px;
		}
		.el-input__icon{
			line-height: 34px;
		}
	} 
</style>
<style lang="scss" scoped>
	.supply_demand{
		width: 1280px;
		position: relative;
		margin: -62px auto 0;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding-bottom: 50px;
		.left_center{
			min-height: 800px;
			padding: 0 20px 0 18px;
			flex: 1;
			background-color: #fff;
			border-radius: 4px 4px 0 0;
			.pager{
				padding-bottom: 25px;
			}
			.tit{
				padding: 28px 0 12px 5px;
				color: #222;
				font-size: 16px;
				line-height: 22px;
				font-weight: 600;
			}
			.condition{
				display: flex;
				align-items: center;
				border: 1px solid #DCDFE6;
				padding: 10px 18px;
				.mode{
					border-radius: 4px;
					color: #333;
					font-size: 14px;
					line-height: 20px;
					padding: 6px 17px;
					margin-right: 17px;
					background-color: transparent;
					cursor: pointer;
					&.active{
						color: #fff;
						background-color: #0DAD94;
					}
				}
				.search{
					color: #222;
					font-size: 14px;
					line-height: 20px;
					margin-left: 18px;
					display: flex;
					align-items: center;
					.first{
						margin-right: 6px;
					}
					.second{
						margin-left: 6px;
					}
					.commit{
						color: #222;
						font-size: 14px;
						line-height: 20px;
						padding: 5px 18px;
						margin-left: 10px;
						border: 1px solid #DCDFE6;
						cursor: pointer;
					}
				}
			}
			.list_tit{
				border: 1px solid #E6E6E6;
				border-top: none;
				background-color: #FCFCFC;
				padding: 9px 0 11px 12px;
				display: flex;
				align-items: center;
				.name{
					width: 31%;
				}
				.demand{
					width: 15.3%;
				}
				.time{
					width: 16.2%;
				}
				.company{
					width: 22.8%;
				}
				.phone{
					flex: 1;
				}
			}
			.supply_demand_list{
				padding-bottom: 22px;
				.demand_item{
					cursor: pointer;
					padding: 24px 0 24px 13px;
					border-bottom: 1px solid #E6E6E6;
					display: flex;
					align-items: flex-start;
					&:last-child{
						border-bottom: none;
					}
					.demand_tit{
						width: 31%;
						padding-right: 15px;
						box-sizing: border-box;
						.name{
							color: #0DAD94;
							font-size: 15px;
							line-height: 20px;
							margin-bottom: 6px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}
						.time{
							color: #666;
							font-size: 13px;
							line-height: 18px;
						}
					}
					.number{
						width: 15.3%;
						font-size: 13px;
						line-height: 18px;
						span{
							color: #DB363C;
						}
					}
					.end_time{
						width: 16.2%;
						.time{
							color: #666;
							font-size: 13px;
							line-height: 18px;
							margin-bottom: 7px;
						}
						.remaining_time{
							color: #666;
							font-size: 13px;
							line-height: 18px;
							span{
								color: #DB363C;
								margin: 0 5px;
							}
						}
					}
					.company_name{
						width: 22.8%;
						color: #222;
						font-size: 13px;
						line-height: 18px;
					}
					.phone{
						flex: 1;
						color: #666;
						font-size: 13px;
						line-height: 18px;
					}
				}
			}
		}
		.right_info{
			margin-left: 18px;
			min-height: 800px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.publish_info{
				width: 242px;
				min-width: 242px;
				box-sizing: border-box;
				padding: 20px 22px 24px;
				background-color: #fff;
				margin-bottom: 18px;
				border-radius: 4px 4px 0 0;
				.tit{
					color: #333;
					font-size: 18px;
					line-height: 25px;
					font-weight: 600;
					margin-bottom: 2px;
					text-align: center;
				}
				.num{
					margin-bottom: 14px;
					text-align: center;
					color: #333;
					font-size: 14px;
					line-height: 20px;
					span{
						color: #E62032;
						font-size: 14px;
						line-height: 20px;
					}
				}
				.form_info{
					display: flex;
					flex-direction: column;
					.choose-btn{
						display: flex;
						justify-content: space-between;
						.btn{
							cursor: pointer;
							text-align: center;
							// width: calc(100% / 2 - 47px);
							border-radius: 5px;
							padding: 0 20px;
							height: 30px;
							line-height: 30px;
							margin-bottom: 10px;
							border: 1px solid #E5E5E5;
						}
						.gong-styles{
							color: #0DAD94;
							border: 1px solid #0DAD94;
						}
						.gong-styles:hover{
							border: 1px solid #0DAD94;
						}
					}
					.goods_name{
						border: 1px solid #E5E5E5;
						color: #333;
						font-size: 12px;
						line-height: 16px;
						padding: 8px;
						outline: none;
						margin-bottom: 10px;
					}
					input::placeholder{
						color: #888;
						font-size: 12px;
						line-height: 16px;
					}
					.spec{
						display: flex;
						align-items: center;
						margin-bottom: 10px;
						input{
							width: calc( ( 100% - 10px ) / 2 );
							flex: 1;
							margin-right: 10px;
							padding: 8px;
							border: 1px solid #E5E5E5;
							outline: none;
							color: #333;
							font-size: 12px;
							line-height: 16px;
							&:last-child{
								margin-right: 0;
							}
						}
						input::placeholder{
							color: #888;
							font-size: 12px;
							line-height: 16px;
						}
					}
					.date,.mobile{
						padding: 8px;
						border: 1px solid #E5E5E5;
						outline: none;
						color: #333;
						font-size: 12px;
						line-height: 16px;
						margin-bottom: 10px;
					}
					.verification_code{
						display: flex;
						align-items: center;
						margin-bottom: 8px;
						.code{
							width: calc( ( 100% - 10px ) / 2 );
							flex: 1;
							margin-right: 10px;
							padding: 8px;
							border: 1px solid #E5E5E5;
							outline: none;
							color: #333;
							font-size: 12px;
							line-height: 16px;
						}
						.btn{
							min-width:105px;
							cursor: pointer;
							width: calc( ( 100% - 10px ) / 2 );
							color: #333;
							font-size: 12px;
							line-height: 16px;
							text-align: center;
							background-color: #F4F4F4;
							padding: 8px 0;
							border: 1px solid #E5E5E5;
							border-radius: 2px;
						}
					}
				}
				.publish{
					display: flex;
					align-items: center;
					justify-content: center;
					.btn{
						cursor: pointer;
						border-radius: 50px;
						color: #fff;
						font-size: 14px;
						line-height: 20px;
						padding: 6px 42px;
						background-color: #0DAD94;
					}
				}
			}
			.recent_transaction{
				flex: 1;
				width: 242px;
				min-width: 242px;
				box-sizing: border-box;
				background-color: #fff;
				.tit{
					padding: 18px 0 18px 14px;
					color: #333;
					font-size: 16px;
					line-height: 22px;
					font-weight: 600;
				}
				.empty_data{
					text-align: center;
				}
				.record_list{
					padding: 0 12px 16px 14px;
					.record_item{
						padding: 12px 0;
						border-bottom: 1px solid #F4F5F7;
						display: flex;
						align-items: center;
						justify-content: space-between;
						&:last-child{
							border-bottom: none;
						}
						.left_info{
							flex: 1;
							.name{
								color: #333;
								font-size: 14px;
								line-height: 20px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 1;
								-webkit-box-orient: vertical;
								margin-bottom: 4px;
							}
							.time{
								color: #888;
								font-size: 12px;
								line-height: 16px;
							}
						}
						.price{
							color: #FB1E1E;
							font-size: 14px;
							line-height: 20px;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
	.more{
		display: flex;
		justify-content: center;
		margin-bottom: 8px;
		cursor: pointer;
		border: 1px solid #0DAD94;
		border-radius: 2px;
		height: 30px;
		line-height: 30px;
	}
	.more-add{
		color: #0DAD94;
		font-size: 12px;
		text-align: right;
	}
	.more-item{
		display: flex;
		margin-bottom: 25px;
		.dialog-title{
			width: 80px;
			margin-right: 15px;
		}
	}
	.buyer-message{
		width: 300px;
	}
	.publish-close{
		display: flex;
		justify-content: center;
		.btn{
			cursor: pointer;
			border-radius: 50px;
			color: #fff;
			font-size: 14px;
			line-height: 30px;
			padding: 6px 42px;
			background-color: #0DAD94;
			width: 100px;
			text-align: center;
		}
	}
	.upload-box{
		width: calc(100% - 100px);
	}
	.choose-type{
		font-size: 12px;
		color: #888;
		line-height: 30px;
	}
</style>